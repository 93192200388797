body, html {
    /*height: 100vh; */
    font-size: 14px;
    background-image: url('./assets/Global/Background.jpg');
    background-attachment: fixed;
}

.App {
    text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
    top: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Header-logo {
    padding: 30px 0px;
    width: 300px;
    pointer-events: none;
}

.Header-menu {
    width: 100%;
    background: #2F2E2E;
    font-size: 14px;
    color: white;
    padding: 6px 0px 6px 0px;
}

.Header-menu a {
    color: white;
    text-decoration: none;
}

.App-content {
    /*height: calc(100vh - 160px);*/
    background:rgba(255,255,255,0.85);
    padding-bottom: 100px;
    /*background-attachment: fixed;*/
    /*overflow-y: auto;
    overflow-x: hidden;*/
}

.Band-div-light {
    padding-top: 45px;
    padding-bottom: 45px;
}

.Band-div-dark {
    background:rgba(255,255,255,0.4);
    padding-top: 45px;
    padding-bottom: 45px;
}

.App-footer {
    background-color: #2F2E2E !important;
    color: white !important;
    padding: 40px 40px !important;
    text-align: left;
}

.App-footer a {
    color: white;
    text-decoration: none;
}

.App-link {
  color: #61dafb;
}

.Content-center {
    margin: auto;
    max-width: 720px;
    padding: 0px 12px 0px 12px;
}

.Content-left {
    text-align: left;
}

.Header-big {
    font-size: calc(20px + 2vmin);
    line-height: 1.1em;
    /*padding-top: 10vh;*/
    margin: 0px;
    font-family: 'D-Din', sans-serif;
}

.Header-sub {
    margin: 0px;
    padding-bottom: 40px;
}

.Quote-button {
    background-color: #2F2E2E !important;
    color: white !important;
    padding: 5px 60px !important;
    border-radius: 25px !important;
}

.Send-button {
    background-color: #2F2E2E !important;
    color: white !important;
    padding: 5px 60px !important;
    border-radius: 25px !important;
}


.Print-options {
    margin-top: 100px;
    width: 100%;
    background-color: #2F2E2E !important;
    color: white !important;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Option-header {
    font-family: 'D-Din', sans-serif;
    font-size: 24px;
    margin: 8px;
}

.Option-text {
    font-size: 14px;
}

.Scroll-image {
    object-fit: cover;
    width: 720px;
    max-height: 450px;
}

.Scroll-text {    
    background:rgba(47,46,46,0.85);
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sticky-wrapper {
    position: relative;
    height: 10rem; 
}

.sticky .sticky-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}