@font-face {
    font-family: D-Din;
    src: url("./assets/d-din/D-DIN.otf") format("opentype");
}

@font-face {
    font-family: D-Din;
    src: url("./assets/d-din/D-DIN-Bold.otf") format("opentype");
    font-weight: bold;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2F2E2E;
  font-size: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
